<template>
  <div class="company auth-wrapper auth-v3">
    <b-row class="auth-inner m-0">

      <!-- Forgot password-->
      <b-col lg="6" class="recover-password recover-password__mt-0 text-center">

        <!-- brand-logo -->
        <b-link class="brand-logo">
          <h2>
            <b-img
                class="logo-size"
                src="~@/assets/images/logo/company-logo.png"
                alt="Divvy" />
          </h2>
        </b-link>
        <!-- brand-logo -->

        <h2 class="recover-password__title">Solicitação enviada com sucesso!</h2>
        <p class="recover-password__paragraph recover-password__paragraph-0 mt-5 mb-5">
          Em alguns minutos você receberá um e-mail com link para resetar sua senha.
        </p>

        <!-- form -->
        <div id="btt">
          <div class="company__confirmed-button company-color">
            <feather-icon icon="CheckIcon" class="w-100 h-100" />
          </div>
        </div>

        <p class="text-center mt-2">
          <b-link
            :to="{ name: 'company-login' }"
            class="back">
            Voltar
          </b-link>
        </p>
      </b-col>

      <b-col g="6" md="6" class="register-background">
        <div class="register-background-text">
          <h1 class="register-background-text__title">
            Compartilhe imóveis e ganhe comissões rápidas e fáceis
            <span class="register-background-text__title--company">!</span>
          </h1>
          <p class="register-background-text__paragraph">
            Simples, fácil e seguro para qualquer pessoa investir no mercado
            imobiliário.
          </p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
  },
  data() {},
  methods: {},
}
</script>

<style lang="scss">
body {
  background-color: #fff !important;
}
a.back {
  color: #000;
  font-weight: 500;
}
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '../sass/_recover-password.scss';

@import '../sass/variables.scss';
@import '../sass/divvy-form.scss';
@import '../sass/register-background-text.scss';
@import '../sass/company.scss';

.register-background {
  display: none;

  @media (min-width: 992px) {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background-image: url('~@/assets/images/pages/background_company.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  box-shadow: none !important;
}
.button-center-position {
  position: absolute;
  margin-top: 85px !important;
  left: 47.3%;
}
.input-group {
  &:not(.bootstrap-touchspin):focus-within {
    .color-input-group > div.input-group-text {
      border-color: #00d999 !important;
    }
  }
}

.logo-size {
  width: 40px;
}
</style>
